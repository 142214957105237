import React from 'react'
import Blob from './Blob'
import { Link } from 'gatsby'
const Hero = () => {
    return (
        <section className="h-[calc(100vh-91px)] grid items-center justify-center relative isolate lg:h-[calc(100vh-160px)] overflow-x-clip">
            <div className="absolute z-[-1] top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2 max-w-lg lg:max-w-7xl lg:max-h-full lg:-translate-y-0 lg:top-[-35%] lg:translate-x-0">
                <Blob />
            </div>

            <div className="container">
                <div className="grid">
                    <div className="grid grid-cols-1 md:grid-cols-12 place-items-center">
                        <div className="md:w-full md:col-span-6 md:col-start-1 max-w-[600px] text-center md:text-left lg:col-start-1">
                            <h1 className="font-bold page-title leading-tight max-w-xl">
                                Bespoke, beautifully crafted development
                                services.
                            </h1>
                        </div>
                        <div className="mt-8 md:mt-[0] md:col-start-8 md:col-span-5 z-[2] max-w-[400px]">
                            <p className="w-5/6 md:w-full sm:text-left text-center mx-auto text-sm md:text-lg md:leading-9 text-[#1D2333]/[0.8] tracking-[-0.025rem]">
                                Saigon Digital is a tech focused creative
                                agency, with a special interest in delivering
                                fast, flexible and user centric digital
                                solutions.
                            </p>
                        </div>
                    </div>
                    <div className="flex lg:flex-row flex-col items-center mt-6 gap-8 flex-wrap">
                        <a class="mx-auto max-w-max md:mx-0" target="_blank" href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ16YM_W-6RTPHNhNimw5PLy0hA-VsGMKuB8bvBLsmEb9Ez9HEppAKiGxU5EFrr8naaIXVYa4AO0">
                            <button type="button" onClick={() => {
                                window.dataLayer.push({
                                    event: "kieran_consultation",
                                    eventCategory: "consultation"
                                })
                            }}
                                class="button group inline-flex items-center gap-x-2 gray-btn ">Book a Consultation</button>
                        </a>
                        <Link to="/contact">
                            <div 
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "contactbutton_click",
                                        eventCategory: "CTA"
                                    })
                                }}
                                role="button" 
                                className="uppercase duration-300 text-dark-gunmetal btn-pulse  hover:!gap-x-4 !tracking-normal flex items-center !gap-1.5 !text-base w-fit border-b border-dark-gunmetal"
                            >
                                contact us
                                <svg className="size-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                                </svg>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero
